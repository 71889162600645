




























































import { Component, Vue } from 'vue-property-decorator';
import { getLoanSummary } from '@/scripts/api/login';
import * as library from '@/scripts/utils/library';
import moment from 'moment';
import ViewTitle from '@/components/ViewTitle.vue';
import LoanStatus from '@/components/LoanStatus.vue';

export default Vue.extend({
    components: {
        ViewTitle,
        LoanStatus,
    },
    data() {
        return {
            tableData: [],
            lastRefreshTime: '',
            refreshing: false,
        };
    },
    mounted() {
        this.refreshLoanAppList();
    },
    methods: {
        formatDateTimeCellValue(row: any, column: any, cellValue: any, index: any) {
            return library.formatDateTime(cellValue);
        },
        formatNumberCellValue(row: any, column: any, cellValue: any, index: any) {
           return library.formatNumber(cellValue);
        },
        formatLoanStatus(cellValue: string, expired: string) {
            return library.formatLoanStatus(cellValue, expired);
        },
        formatLoanStatusCellValue(row: any, column: any, cellValue: any, index: any) {
            return library.formatLoanStatus(cellValue, row.expired);
        },
        refreshLoanAppList() {
            this.refreshing = true;
            getLoanSummary().then((response) => {
                    this.tableData = response.data;
                    this.lastRefreshTime = moment().format('YYYY-MM-DD HH:mm:ss');
                }).catch((error) => {
                    console.debug('refresh error:' + error);
                    library.errHandle(this, error);
                }).finally(() => {
                    this.refreshing = false;
                });
        },
    },
});
