




import { Component, Prop, Vue } from 'vue-property-decorator';
import * as library from '@/scripts/utils/library';

export default Vue.extend({
    props: {
        status: String,
        expired: String,
    },
    computed: {
        statusName() {
            return library.formatLoanStatus(this.status, this.expired);
        },
        statusColor() {
            return library.getLoanStatusColor(this.status, this.expired);
        },
        statusIcon() {
            return library.getLoanStatusIcon(this.status, this.expired);
        },
    },
});
